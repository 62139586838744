<template>
  <div class="container" v-view="knowledgeInUseAnimation">
    <div class="knowledgeInUse">
      <!-- <img class="bg" src="../assets/kinuse.jpg"> -->
      <img class="bg" src="../assets/known.png">
      <h1 class="sectionTitle">KIU:<br>{{ $t('knowledgeInUse.title') }}</h1>
        <p class="lead">{{ $t('knowledgeInUse.lead') }}</p>
        <router-link tag="button" to="/about" class="btn btn-secondary --gradient --cta"><span>{{ $t('knowledgeInUse.btn') }}</span></router-link>
    </div>
  </div>
</template>

<script>
import { TimelineMax } from 'gsap';

export default {
  data() {
    return {
      animated: false,
      knowledgeInUseAnimationTl: new TimelineMax({paused: true})
    }
  },
  methods: {
    knowledgeInUseAnimation: function(e) {
      if((e.type == 'enter' || e.type == 'progress') && e.percentInView > 0.25 && !this.animated) {
        this.animated = true;
        this.knowledgeInUseAnimationTl.play(0);
      }
    }
  },
  mounted() {
    this.knowledgeInUseAnimationTl.from(".knowledgeInUse .bg", 1,{alpha: 0})
    }
};
</script>

<style scoped lang="sass">
.container
  position: relative
  z-index: -1
  border-radius: var(--borderRadius-sections)
  min-height: 500px
  // overflow: hidden
  padding-bottom: 2rem
  max-width: var(--breakpoint-xl)
  margin: 0 auto
h1 
  // text-align: center
  position: relative
  z-index: 3
  // margin-top: 2rem
  order: 1
.knowledgeInUse
  margin: 3rem 0 5rem
  overflow-x: hidden
  padding-bottom: 1rem
  padding: 0 var(--section-space)
  display: flex
  flex-direction: column
  gap: 2rem
.bg
  // margin-top: -4rem
  position: relative
  z-index: -1
  top: 0
  right: 0
  order: 2
  background-size: contain
  background-position: top center
  background-repeat: no-repeat
  transform-origin: right bottom
  // width: calc(100% + var(--container-space))
  width: 100%
  // margin-right: calc(-1 * var(--container-space))
p 
  order: 3
  margin-bottom: 0
  position: relative
  z-index: 1
.btn
  display: block
  margin: 0 auto
  margin-left: 0
  order: 4
.lead
  text-align: left
  margin-bottom: 2rem
@media (min-width: 768px)
  .container
    overflow: visible
    margin-top: 4rem
  .knowledgeInUse
    // min-height: 65vh
    justify-content: center
    text-align: left
    display: flex
    align-items: flex-start
    flex-direction: column
    .lead
      padding-right: 2rem
      margin: 2rem 0
      box-sizing: border-box
  .btn
    margin: 0
  h1
    text-align: left
  p 
    text-align: left
    margin: inherit
    margin-bottom: 2rem
    padding: 0
@media (min-width: 992px)
  .container
    // margin-top: 0
  .bg
    position: absolute
    bottom: 0
    top: initial
    // right: -5rem
    left: initial
    margin: 0
    width: 50%
    object-fit: contain
    object-position: right top
    opacity: .7
    margin-top: 0
  .lead
    max-width: 50%
    // max-width: 50%;
    // filter: saturate(0.5)
  // h1
    // margin-top: -80px
</style>
