<template>
  <div class="view">
    <div class="container">
      <div class="header">
        <p class="lead">{{ $t('about.lead') }}</p>
      </div>
      <div class="overCardContainer">
        <overCard :title="$t('about.titlePhilosophy')"
                  :excerpt="$t('about.subtitlePhilosophy')"
                  link="/about/philosophy">
        </overCard>
        <overCard :title="$t('about.titleBackground')"
                  :excerpt="$t('about.subtitleBackground')"
                  link="/about/background"
                  class="--background">
        </overCard>
        <overCard :title="$t('about.titleBoard')"
                  :excerpt="$t('about.subtitleBoard')"
                  link="/about/board"
                  class="--board">
        </overCard>
        <overCard :title="$t('about.titleMilestones')"
                  :excerpt="$t('about.subtitleMilestones')"
                  link="/about/milestones"
                  class="--milestones">
        </overCard>
      </div>
    </div>
    <ourAllies></ourAllies>
  </div>
</template>


<script>
import OurAllies from '@/components/ourAllies.vue'
import overCard from '@/components/overCard.vue'

import MessageBoxMixin from '@/mixins/MessageBox/emitter.js';

export default {
  components: {
    OurAllies,
    overCard,
  },
  mixins: [ MessageBoxMixin ],
  methods: {
    on_click() {
      this.success_message("Título del modal", "Mensaje de prueba", "Aceptar", () => alert("MessageBox ON_CLOSE callback"));
    }
  }
};

</script>

<style scoped lang="sass">
.overCardContainer
  display: flex
  flex-direction: column
  gap: 1rem
.overCard
  &.--background
    background-image: url("../assets/about/about2.jpg")
  &.--board
    background-image: url("../assets/about/about3.png")
  &.--milestones
    background-image: url("../assets/about/about4.png")
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
@media (min-width: 768px)
  .view
    padding-bottom: 6rem
  .overCardContainer
    max-width: 980px
    margin: 0 auto
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-between
  .overCard
    flex: calc(50% - 1rem)
    margin-bottom: 0
</style>
