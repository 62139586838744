<template>
  <div id="app">
    <Session ref="session"></Session>
    <Intro></Intro>
    <Nav></Nav>
    <breadcrumb v-if="showBreadcrumb()"></breadcrumb>
    <transition name="fade" mode="out-in">
      <headerComponent v-if="showHeader()"></headerComponent>
    </transition>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath" @show_messagebox="on_show_messagebox"></router-view>
    </transition>
    <footerComponent @show_messagebox="on_show_messagebox"></footerComponent>
    <!-- MessageBox component -->
    <MessageBox @close_messagebox="on_close_messagebox"
                :ref="message_box.ref_name" v-if="message_box.active"
                :title="message_box.title" :message="message_box.message"
                :button="{ caption: message_box.button_caption }"/>
  </div>
</template>

<script>
/* eslint-disable */
import Session from '@/components/Session.vue';
import Intro from '@/components/intro.vue'
import FooterComponent from '@/components/footerComponent.vue'
import Breadcrumb from '@/components/breadcrumb.vue'
import Nav from '@/components/nav.vue'
import HeaderComponent from '@/components/headerComponent.vue'

import MessageBox from '@/components/MessageBox';
import MessageBoxMixin from '@/mixins/MessageBox/observer';

export default {
  name: 'app',
  mixins: [ MessageBoxMixin ],
  components: {
    Session,
    Intro,
    Nav,
    FooterComponent,
    HeaderComponent,
    Breadcrumb,
    MessageBox
  },
  data() {
    return {
      menuOpen: false,
      headerContainer: [
        "solutions",
        "about",
        "philosophy",
        "board",
        "milestones",
        "background",
        "contact",
        "request demo",
        "agencies",
        "new-agency",
        "session-reset",
        "device-reset",
        "device-reset-confirm",
        "user-reset",
        "user-reset-confirm",
        "news",
        "services",
        "clients"
      ],
      breadcrumbContainer: [
        "home"
      ],
    }
  },
  created() {
    if (!this.$session.exists()) {
      this.$session.start();
    }
  },
  mounted() { /*
    window.console.info("===  MOUNTED APP.vue ===");
    window.console.info(this.$refs["session"]);
    window.console.info("=== /MOUNTED APP ==="); */
  },
  methods: {
    showHeader() {
      const currentRoute = this.$route.name;
      return Boolean(this.headerContainer.find(x => x === currentRoute))
    },
    showBreadcrumb() {
      const currentRoute = this.$route.name;
      return Boolean(this.breadcrumbContainer.find(x => x !== currentRoute))
    },
  }
};
</script>

<style lang="sass">
@import '../node_modules/@fontsource/lexend-deca/index.css'
@import './assets/colors.sass'
@import './assets/css/animations.scss'

// ::-webkit-scrollbar
//   width: 8px
// ::-webkit-scrollbar-track
//   background: #f1f1f1
// ::-webkit-scrollbar-thumb
//   background: #888
//   border-radius: 7px
// ::-webkit-scrollbar-thumb:hover
//   background: #555
//   cursor: pointer
\:root
  --fontFamily: "Lexend Deca", system-ui
  --fontWeight-light: 100
  --fontWeight-regular: 200
  --fontWeight-bold: 400
  // ---xs: 0.75rem
  // --font-size-sm: 14px
  // --font-size-md: 1rem
  // --font-size-lg: 1.5rem
  // --font-size-xl: 1.8rem
  // --font-size-d1: 2.5rem
  // --borderRadius-xs: 0.2rem
  // --borderRadius-sm: 0.25rem
  // --borderRadius-md: 0.75rem
  // --borderRadius-lg: 0.85rem
  // --borderRadius-xl: 1rem
  // --borderRadius-d1: 40px
  // --borderRadius-d2: 120px
  // --borderRadius-sections: 0
  // --containerWidth: 1500px
  --rU: 1vw // Responsive Unit
  --container-space: 1.25rem // Container Padding
  // --nav-bg: alpha(var(--color-primary), 0.97) // Navbar background color
  // --blueIndigo: #3F51B5 -
@media (min-width: 768px)
  // \:root
  //   --font-size-xs: 0.55rem
  //   --font-size-sm: 0.7rem
  //   --font-size-d1: 2rem
  //   --borderRadius-sections: 0 0 0 40px
  //   --container-space: 1rem // Container Padding
  //   font-size: 16px

@media (min-width: 992px)
  \:root
    --container-space: 4rem // Container Padding
    // --borderRadius-sections: 0 0 0 0
    // font-size: 22px
@media (min-width: 1200px)
  \:root
    --section-space: 0
  //    font-size: 22px
  //   --font-size-xs: calc(0.75rem + 0.1vw)
  //   --font-size-sm: calc(0.85rem + 0.1vw)
  //   --font-size-md: calc(1rem + 0.1vw)
  //   --font-size-lg: calc(1.2rem + 0.1vw)
  //   --font-size-xl: calc(1.8rem + 0.1vw)
  //   --font-size-d1: calc(2.5rem + 0.1vw)

#app
  background-color: #02000A
  color: white
html, body
  font-size: 16px
  margin: 0
  font-family: var(--fontFamily)
body
  overflow: hidden
.container
  padding: 0
  max-width: var(--breakpoint-xl)
  width: 100%
  margin: 0 auto
  display: flex
  flex-direction: column
  &.--fluid
    max-width: initial
.view
  // border-radius: var(--borderRadius-sections)
  position: relative
  z-index: 2
  // padding-bottom: 2rem
  // background-color: var(--color-white)
p
  font-size: 1rem
  line-height: 190%
  font-weight: 600
em
  font-size: 12px
  &.error
    color: red
    font-style: italic
    padding-top: 0
    top: -5px
    position: relative
h1
  font-size: var(--font-size-lg)
  font-family: var(--fontFamily)
  font-weight: 200
h1.sectionTitle
  width: 100%
p, h1, h3
  margin: 0
.viewSeparator
  width: 100%
  position: relative
  bottom: -2px
.btnGlyph
  background-color: var(--color-overlay-20)
  color: white
  border: 0
  border-radius: var(--borderRadius-md)
  padding: 1rem
.ml-2
  margin-left: 0.5rem
.mr-2
  margin-right: 0.5rem
.mr-3
  margin-right: 1rem
.mb-3
  margin-bottom: 1rem
.mb-2
  margin-bottom: 0.5rem
.mb-0
  margin-bottom: 0 !important
.mx-3
  margin-left: 1rem
  margin-right: 1rem
.mt-3
  margin-top: 1rem !important
.mt-4
  margin-top: 1.5rem
.lead
  font-family: var(--font-family)
  font-style: normal
  font-weight: normal
  font-size: var(--font-size-smd)
  line-height: 2.15rem
  text-align: center
  letter-spacing: 0.3px
.surface
  background-color: #000d1e
  border-radius: var(--borderRadius-md) var(--borderRadius-md) var(--borderRadius-d1) var(--borderRadius-md)
.header
  margin: 0 1.5rem
  padding: 2rem 0
// tabPanel
.tabPanel
  background-color: var(--color-contrast-lower)
  border-radius: var(--b-radius-lg)
  // box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.09)
  border: 1px solid var(--color-overlay-20)
  padding: 0
.tabPanelContent
  display: none
  padding: 1.5rem
  &.--formPanel
    padding-bottom: 0
  &.--active
    display: block
.tabPanelHeader
  height: 100%
  min-height: 100%
  display: flex
  flex-direction: row
  // text-align: center
  border-radius: var(--borderRadius-md) var(--borderRadius-md) 0 0
  overflow: hidden
  color: var(--color-contrast-low)
  &.--alignCenter
    .tabPanelOption
      align-items: center
.tabPanelOption
  display: flex
  flex-direction: column
  justify-content: center
  flex: 1
  padding: 1.5rem
  font-weight: bolder
  border-bottom: 2px solid transparent
  letter-spacing: 0.5px
  font-size: var(--font-size-lg)
  transition: background-color 0.3s ease, border-bottom 0.3s ease, color 0.3s ease
  cursor: pointer
  &.--active
    border-bottom: 2px solid var(--color-primary)
    color: var(--color-contrast-high)
button.hooper-indicator
  width: 100%
  flex: 1
  height: 1rem !important
  cursor: pointer
  transition: background-color 0.2s ease
  margin: 0
  background-color: transparent
  border-radius: 0
  outline: none
  &:hover
    background-color: black
  &.is-active
    background: linear-gradient(45deg, var(--color-terciary) ,  transparent)
    cursor: initial
.hooper-indicators
  width: 100%
  border-top-right-radius: var(--b-radius-lg)
  overflow: hidden
  padding: 0
  li
    display: flex
    width: 100%
    height: 1rem
    // margin-right: 0.5rem
.hooper-navigation
  button
    outline: none
    height: 2.5rem
  svg
    transform: scale(3.5)
    opacity: 0.2
    transition: opacity 0.3s ease
    &:hover
      opacity: 0.5
.VideoBg__content
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  // padding: 0 var(--container-space)
  color: white
  background: rgba(22, 0, 51, .6)
.sectionTitle
  font-size: var(--font-size-xl)
// FIXES PARA BLOG
// .separator
//   a
//     margin: 0 !important
//   a, img
//     width: 100%
//     border-radius: 4px
//   img
//     object-fit: contain
.deviceReset__help
  display: flex
  flex-direction: column
  align-items: center
  border: 1px solid var(--color-overlay-30)
  margin: 3rem auto 0
  padding: 2rem 4rem
  border-radius: var(--b-radius-lg)
@media (min-width: 768px)
  h1
    font-size: var(--font-size-d1)
    font-weight: 200
  // .view
  //   padding-bottom: var(--borderRadius-d2)
  // .btn
  //   &.--sm
  //     font-size: var(--font-size-sm)
  p.lead
    font-size: 1.15rem
    line-height: 2.15rem
    max-width: 1000px
    margin: 0 auto
  // .articleTitle
  //   padding-left: 60px
  //   padding-bottom: 0.5rem
  //   min-height: 90px
  //   display: flex
  //   align-items: flex-start
  //   flex-direction: column
  //   justify-content: center
  //   p
  //     font-size: var(--font-size-md)
  //     line-height: 1.5rem
  // .inputContainer
  //   input[type=text], input[type=textarea], input[type=email], input[type=tel], textarea, select
  //     font-size: var(--font-size-sm)
@media (min-width: 992px)
  .sectionTitle
    font-size: var(--font-size-d1)
@media (min-width: 1500px)
  #app
    min-height: 100vh
@media (max-width: 767px)
  .hooper-next, .hooper-prev
    bottom: 110px
    top: initial !important
  .VideoBg__content
    justify-content: flex-start
    align-items: center
    padding-top: 7.5rem
</style>
<style lang="sass">
\:root
  --btn-font-family: var(--font-family)
  --btn-padding-y: .375rem
  --btn-padding-x: .75rem
  --btn-color: var(--color-onBackground)
  --btn-border-radius: 0
  // --btn-border-radius: var(--b-radius-sm)
  --btn-background-color: transparent
  --btn-background-color-hover: var(--color-overlay-10)
  --btn-background-color-focus: var(--color-overlay-20)
  --btn-border-width: 0
  --btn-border-style: solid
  --btn-border-color: var(--color-overlay-30)
  --btn-border-color-hover: var(--color-overlay-50)
  --btn-border-color-focus: var(--color-overlay-60)
  --btn-boxShadow-color-hover: var(--color-overlay-20)
  --btn-boxShadow-color-focus: var(--color-overlay-30) 
  --btn-boxShadow-spreadRadius-hover: 0.2rem
  --btn-boxShadow-spreadRadius-focus: 0.2rem
  --btn-font-size: 1rem
  --btn-lineHeight: 1.5
  --btn-opacity-disabled: .5

.btn
  display: inline-block
  font-family: var(--btn-font-family)
  padding: var(--btn-padding-y) var(--btn-padding-x)
  color: var(--btn-color)
  text-align: center
  white-space: nowrap
  border-radius: var(--btn-border-radius)
  vertical-align: middle
  cursor: pointer
  user-select: none
  background: linear-gradient(45deg, var(--btn-background-color), transparent)
  border: var(--btn-border-width) var(--btn-border-style) var(--btn-border-color)
  font-size: var(--btn-font-size)
  line-height: var(--btn-lineHeight)
  outline: none !important
  transition: box-shadow .2s ease, border-color .2s ease
  will-change: box-shadow, border-color
  border-right: 3px solid var(--btn-border-color)

  &:hover
    box-shadow: 0 0 0 var(--btn-boxShadow-spreadRadius-hover) var(--btn-boxShadow-color-hover), inset 0 0 3rem var(--btn-background-color-hover)
    border-color: var(--btn-border-color-hover)

  &:focus
    box-shadow: 0 0 0 var(--btn-boxShadow-spreadRadius-focus) var(--btn-boxShadow-color-focus), inset 0 0 3rem var(--btn-background-color-focus)
    border-color: var(--color-overlay-50)

  &:active
    box-shadow: 0 0 0 0 transparent, inset 0 0 3rem inset 0 0 3rem var(--btn-background-color-focus)

  &:disabled
    opacity: var(--btn-opacity-disabled)
    cursor: not-allowed

  &.btn-primary
    background: linear-gradient(45deg, var(--color-primary), transparent)
    border-color: var(--color-primary)
    color: var(--color-onPrimary)

    &:focus
      border-color: var(--color-primary)

  &.btn-secondary
    background: linear-gradient(45deg, var(--color-secondary), secondary)
    border-color: var(--color-secondary)
    color: var(--color-onSecondary)

    &:focus
      border-color: var(--color-secondary)

    &.--gradient
      border: none
      border-right: 3px solid var(--color-secondary)
      background-color: initial
      background: linear-gradient(45deg, var(--color-secondary), transparent)
      color: var(--color-onSecondary)

  &.btn-terciary
    background: linear-gradient(45deg, var(--color-terciary), transparent)
    border-color: var(--color-terciary)
    color: var(--color-onTerciary)

    &.--gradient
      background: linear-gradient(45deg, var(--color-terciary), transparent)
      color: var(--color-onTerciary)

    &:focus
      border-color: var(--color-terciary)

  &.--sm
    font-size: var(--font-size-sm)
    border-radius: var(--b-radius-sm)

  &.--smd
    font-size: var(--font-size-smd)
    border-radius: var(--b-radius-smd)
  
  &.--cta
    padding: 0.5rem 3rem
</style>
<style lang="sass">
\:root
  --input-background-color-focus: var(--color-contrast-low)
  --input-background-color-hover: var(--color-overlay-l-05)
  --input-background-color-focus-hover: white
  --input-background-color-disabled: var(--color-overlay-10)
  --input-background-color: var(--color-contrast-lower)
  --input-background-color-disabled-hover: var(--color-overlay-20)
  --input-boxShadow-color-hover: var(--color-overlay-10)
  --input-color-disabled: #CCC
  --input-color: inherit
  --input-color-placeholder: #CCC
  --input-border-color: var(--color-overlay-l-50)
  --input-border-color-hover: var(--color-overlay-l-80)
  --input-border-color-focus: white
  --input-border-width: 1px
  --input-border-radius: var(--b-radius-sm)
  --input-height: 3rem
  --input-padding-y: .5rem
  --input-padding-x: 1rem
  --input-margin-bottom: 0
  --input-font-size: var(--font-size-smd)
  --input-font-weight: normal
  --input-line-height: var(--font-size-lg)
  --input__helpText-color: var(--color-onBackground)
  --input__label-font-size: var(--font-size-base)
  --input__label-margin-bottom: .5rem
  --input__label-font-weight: inherit
  --inputSelect__caretIcon-color: var(--color-onSurface)
  --inputTag-background-color: transparent
  --inputTag-background-color-hover: var(--color-overlay-10)
  --inputTag-border-color: transparent
  --inputTag-color: inherit
  --inputTag-color-hover: inherit
  --inputTag-border-radius: var(--b-radius-sm)
.datepickerInput, .inputText, .inputSelect
  margin-bottom: 1rem
  color: var(--input-color)
  &__input, input.mx-input, .vue-suggestion-input
    display: block
    width: 100%
    height: var(--input-height)
    padding: var(--input-padding-y) var(--input-padding-x)
    margin-bottom: var(--input-margin-bottom)
    font-weight: var(--input-font-weight)
    color: var(--input-color)
    background-color: var(--input-background-color)
    line-height: var(--input-line-height)
    font-family: var(--font-family-base)
    font-size: var(--input-font-size)
    background-clip: padding-box
    border: var(--input-border-width) solid var(--input-border-color)
    border-radius: var(--input-border-radius)
    transition: box-shadow 0.2s ease, border-color .2s ease, background-color .2s ease
    -webkit-appearance: none
    outline: none
    box-sizing: border-box
    &:hover
      background-color: var(--input-background-color-hover)
      border-color: var(--input-border-color-hover)
      box-shadow: 0 0 0 .2rem var(--input-boxShadow-color-hover)
    &::placeholder
      color: var(--input-color-placeholder) !important
      opacity: .4 !important
    &:focus
      border-color: var(--input-border-color-focus)
      box-shadow: none
      background-color: var(--input-background-color-focus)
    &:focus
      &:hover
        border-color: var(--input-background-color-focus-hover)
    &:disabled
      background-color: var(--input-background-color-disabled)
      color: var(--input-color-disabled)
      cursor: not-allowed
      border-color: transparent
      &:hover
        background-color: var(--input-background-color-disabled-hover)
        box-shadow: none
      & + *[class*="__icon"]
        opacity: .25
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus
      border: 1px solid var(--color-secondary)
      background-color: var(--color-onSurface)
      -webkit-text-fill-color: var(--color-onSurface)
      -webkit-box-shadow: 0 0 0px 1000px var(--color-surface) inset
    &[aria-invalid="true"]
      border-color: var(--color-error)
  &__icon
    position: absolute
    z-index: 5
    left: 0.5rem
    top: 0
    bottom: 0
    margin: auto
    height: 2rem
    width: 2rem
    opacity: 0.5
    display: flex
    align-items: center
    justify-content: center
    transition: opacity 0.2s ease
    & + *[class*="__input"]
      padding-left: 2.75rem
  &__label
    font-size: var(--input__label-font-size)
    display: block
    margin-bottom: var(--input__label-margin-bottom)
    font-weight: var(--input__label-font-weight)
  &__group
    position: relative
    height: var(--input-height)
    font-size: var(--font-size-base)
    &.--invalid
      *[class*="__input"]
        border-color: var(--color-error)
    &.--sm
      font-size: var(--font-size-sm)
      &:after
        height: 2rem
  &__feedback, &__helptext
    display: block
    margin-top: 0.5rem
    font-size: var(--font-size-sm)
    color: var(--color-error)
  &__helptext
    color: var(--input__helpText-color)
  &.--sm
    *[class*="__input"]
      height: 2rem
      font-size: var(--font-size-sm)
    *[class*="__group"]
      height: 2rem
    *[class*="__icon"]
      font-size: var(--font-size-sm)
    .inputSelect__group::after, & *[class*="__group"]::after
      height: 2rem
      font-size: var(--font-size-sm)
      right: 0.8rem
  &.--disabled
    *[class*="__icon"]
      opacity: .25
  &.--tag
    *[class*="__input"]
      cursor: pointer
      padding: 0 2rem 0 1rem
      border: 1px solid var(--inputTag-border-color)
      background-color: var(--inputTag-background-color)
      color: var(--inputTag-color)
      border-radius: var(--inputTag-border-radius)
      &:hover
        background-color: var(--inputTag-background-color-hover)
        color: var(--inputTag-color-hover)
// Caret icon
.inputSelect__group::after, .--caret *[class*="__group"]::after
  content: '\f0d7'
  display: flex
  align-items: center
  font-style: normal
  font-variant: normal
  text-rendering: auto
  line-height: 1
  font-family: 'Font Awesome 5 Pro'
  pointer-events: none
  font-weight: 900
  position: absolute
  top: 0
  right: 1rem
  bottom: 0
  z-index: 10
  opacity: 0.5
  color: var(--inputSelect__caretIcon-color)
input.mx-input
  padding-left: 3rem !important
  box-shadow: initial
// TextArea
textarea.inputText__input
  padding: var(--input-padding-x)
// Radio
.inputRadio
  margin-bottom: .75rem
  font-size: var(--font-size-base)
  &.--sm
    font-size: var(--font-size-sm)
  &.--row
    display: flex
    align-items: center
    .inputRadio__container
      margin-right: 1rem
  /* The container */
  &__container
    display: flex
    align-items: center
    position: relative
    cursor: pointer
    user-select: none
    min-height: 2rem
    margin: 0
    padding: 0 0.5rem
    border: 1px solid transparent
    border-radius: var(--b-radius-sm)
    transition: background-color .2s ease
    /* On mouse-over if not checked, add a grey background color */
    &:hover
      background-color: var(--color-overlay-05)
      .inputRadio__input:not(:checked) ~ .inputRadio__checkmark
        background-color: var(--color-overlay-20)
        box-shadow: inset 0 0 0 .35rem var(--color-overlay-10)
  /* Hide the browser's default radio button */
  &__input
    position: absolute
    opacity: 0
    cursor: pointer
    /* When the radio button is checked, add a blue background */
    &:checked  ~ .inputRadio__checkmark
      box-shadow: inset 0 0 0 0.35rem var(--color-surface)
      background-color: var(--color-primary)
      border: 1px solid var(--color-primary)
  /* Create a custom radio button */
  &__checkmark
    height: 1.25rem
    width: 1.25rem
    margin-right: .5rem
    background-color: var(--color-overlay-10)
    border-radius: 50%
    display: inline-block
    transition: background-color .2s ease, box-shadow .2s ease
  // Give label display property
  &__label
    display: block
.inputCheckbox
  display: flex
  align-items: flex-basis
  &__input
    position: absolute
    opacity: 0
    height: 0
    width: 0
    z-index: -1
    pointer-events: none
    &:checked~.inputCheckbox__check
      background-color: var(--color-primary)
      animation: none
      i
        opacity: 1
        color: var(--color-onPrimary)
        transform: scale(1)
  &__label
    display: flex
    align-items: flex-start
    cursor: pointer
    margin: 0
  &__check
    height: 1.5rem
    width: 1.5rem
    min-width: 1.5rem
    background-color: var(--color-surface)
    display: flex
    border: 2px solid var(--color-overlay-50)
    border-radius: var(--b-radius-sm)
    margin: 0
    align-items: center
    cursor: pointer
    justify-content: center
    transition: border .3s ease, background-color .3s ease
    animation: pulse 2s infinite
    i
      transition: opacity .3s ease, color .3s ease, transform 0.3s ease
      opacity: 0
      transform: scale(2)
.inputText
  &.--textarea textarea
    min-height: 200px
.inputCheckbox__label
  gap: .5rem
  align-items: baseline
</style>
