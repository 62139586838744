<template>
  <div class="headerComponent">
    <div class="headerContent" :style="style">
      <h1>{{ headingCaption }}</h1>
      <!-- <img class="viewSeparator" src="../assets/section-separator-w.svg" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerComponent',
  computed: {
    headingCaption() {
      return this.currentRoute !== "home"
        ? this.$t('nav.' + this.currentRoute)
        : "";
    },
    style: function() {
      let image = this.mapping[this.currentRoute]
      if (!image) {
        return ''
      }
      return {
        "background-image": 'url(' + require(`../assets/header/${image}`) +')'
      }
    },
    currentRoute: function () {
      return this.$route.name
    }
  },
  data() {
    return {
      mapping: {
        home: 'h_solutions.jpg',
        solutions: 'solutions_h.jpg',
        clients: 'solutions_h.jpg',
        agencies: 'h_agencies.jpg',
        about: 'h_about.jpg',
        contact: 'h_contact.jpg',
        news: 'h_news.jpg',
        'device-reset': 'h_reset.jpg',
        'user-reset': 'h_resetUser.jpg',
        'new-agency': 'h_newAgency.jpg',
        philosophy: 'h_about.jpg',
        background: 'h_background.jpg',
        board: 'h_board.jpg',
        milestones: 'h_milestones.png',
        'request-demo': 'h_trydemo.jpg'
      }
    }
  }
};
</script>

<style scoped lang="sass">
.headerComponent
  background-color: transparent
  position: relative
  z-index: 3
.headerContent
  height: 252px
  width: 100%
  box-sizing: border-box
  padding-bottom: 2rem
  background-color: var(--color-primary)
  border-radius: var(--borderRadius-sections)
  transition: background-image 0.5s ease
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  display: flex
  flex-direction: column
  justify-content: flex-end
h1
  text-align: center
  color: var(--color-white)
  font-size: var(--font-size-xl)
  text-transform: capitalize
@media (min-width: 768px)
  .headerContent
    border-radius: 0 0 0 0
@media (min-width: 1500px)
  .headerContent
    // height: calc(212px + 1vw)
</style>
