<template>
  <div class="view">
    <div class="container --bg">
      <h1>{{ $t('milestones.titleMision') }}</h1>
      <div class="philosophy__content">
        <p class="lead">{{ $t('milestones.textMision') }}</p>
      </div>
      <!-- <h1>{{ $t('milestones.titleVision') }}</h1>
      <div class="philosophy__content">
        <p class="lead">{{ $t('milestones.textVision') }}</p>
      </div> -->
    </div>
    <div class="container">
      <div class="ourValues">
        <div class="ourValues__timeline"></div>
        <div class="ourValues__value">
          <div class="ourValues__valueContainer --first">
            <div class="ourValues__img">
              <img src="../../assets/about/milestone1.png" alt="">
            </div>
            <div class="ourValues__header">
              <span class="circle"></span>
              <h2>2005</h2>
              <span>{{ $t('milestones.m1') }}</span>
            </div>
            <ul class="ourValues__list">
              <li class="ourValues__text">{{ $t('milestones.m1Text1') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m1Text2') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m1Text3') }}</li> 
            </ul>
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__valueContainer --second">
            <div class="ourValues__img">
              <img src="../../assets/about/milestone2.png" alt="">
            </div>
            <div class="ourValues__header">
              <span class="circle"></span>
              <h2>2018</h2>
              <span>{{ $t('milestones.m2') }}</span>
            </div>
            <ul class="ourValues__list">
              <li class="ourValues__text">{{ $t('milestones.m2Text1') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m2Text2') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m2Text3') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m2Text4') }}</li> 
              <img width='80' src="../../assets/about/amazon.png" alt="">
            </ul>
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__valueContainer --third">
            <div class="ourValues__img">
              <img src="../../assets/about/milestone3.png" alt="">
            </div>
            <div class="ourValues__header">
              <span class="circle"></span>
              <h2>2023</h2>
              <span>{{ $t('milestones.m3') }}</span>
            </div>
            <ul class="ourValues__list">
              <li class="ourValues__text">{{ $t('milestones.m3Text1') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m3Text2') }}</li> 
              <img width='120' src="../../assets/about/travelX.png" alt="">
            </ul>
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__valueContainer --forth">
            <div class="ourValues__img">
              <img src="../../assets/about/milestone4.png" alt="">
            </div>
            <div class="ourValues__header">
              <span class="circle"></span>
              <h2>2024</h2>
              <span>{{ $t('milestones.m4') }}</span>
            </div>
            <ul class="ourValues__list">
              <li class="ourValues__text">{{ $t('milestones.m4Text1') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m4Text2') }}</li> 
              <li class="ourValues__text">{{ $t('milestones.m4Text3') }}</li> 
            </ul>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>


<script>
// import ...
  export default {};
</script>

<style scoped lang="sass">
.view
  // padding-bottom: 4rem
  position: relative
  z-index: 2
  gap: 3rem
  display: flex
  flex-direction: column
  // background-image: url("../../assets/about/philosophybg.png")
  background-size: cover
  background-repeat: no-repeat
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
  width: 100%
  &.--bg
    background: linear-gradient(180deg, var(--color-contrast-lower), transparent)
    padding: 0
    max-width: initial
.lead
  text-align: center
h1
  text-align: center
  margin: 2rem 0 1.25rem
h2
  margin: 0
  font-weight: 100
  font-size: var(--font-size-d1)
.philosophy__content
  width: 100%
  margin: 0 auto
  padding: 0 var(--section-space)
  box-sizing: border-box
  .lead
    line-height: 1.7rem
.ourValues
  display: flex
  flex-direction: column
  gap: 1rem
  padding-bottom: 2rem
.ourValues__value
  flex: 1
  width: 100%
  padding: 0
  box-sizing: border-box
  position: relative
  display: flex
  justify-content: flex-start
  flex-direction: column
  align-items: center
.ourValues__valueContainer
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-content: center
  padding: 1.5rem
  background-image: linear-gradient(180deg, black, transparent)
  border-radius: 30px 30px 0 0
  width: 100%
  box-sizing: border-box
  margin: 0 auto
  flex: 1
  gap: 1rem
  &.--first
    background-image: linear-gradient(180deg, transparent, #003a50)
    .ourValues__header span
      color: #237391
  &.--second
    background-image: linear-gradient(180deg, transparent, #31005a)
    .ourValues__header span
      color: #8c30da
  &.--third
    background-image: linear-gradient(180deg, transparent, #002150)
    .ourValues__header span
      color: #206fdf
  &.--forth
    background-image: linear-gradient(180deg, transparent, #003400)
    .ourValues__header span
      color: #0c7b00
.ourValues__header
  position: relative
  display: flex
  justify-content: center
  flex-direction: column
  font-size: var(--font-size-smd)
  font-weight: bold
  line-height: 1.5
  text-align: center
  align-items: center
  gap: 1rem
  .circle
    display: none
    width: 1rem
    height: 1rem
    background-color: var(--color-terciary)
    border-radius: 50%
.ourValues__text
  font-size: var(--font-size-base)
  text-align: left
  margin: 0 auto
  margin-left: 0
.ourValues__list
  display: flex
  flex-direction: column
  padding: 0 1rem
  li
    margin-bottom: .75rem
  img
    margin: 1rem auto 0 
    display: inline-block
    filter: brightness(20)
.ourValues__img
  border-radius: 50%
  display: flex
  justify-content: center
  img 
    display: flex
    height: auto
    height: 120px
@media (min-width: 768px)
  .ourValues__timeline
    display: flex
    height: 2px
    width: 100%
    background: linear-gradient(45deg, transparent, var(--color-terciary), transparent)
    position: absolute
    top: 197px
    z-index: 10
  .view
    padding: 0
  .philosophy__content
    width: 800px
    padding: 0
  .ourValues
    flex-direction: row
  .ourValues__img
    img
      width: auto
      height: 150px
  .ourValues__header
    .circle
      display: flex
</style>