<template>
  <div class="ourAlliesComponent">  
    <div class="container">
      <div class="ourAllies">
        <h1>{{ $t('ourAllies.title') }}</h1>
        <div class="imgRow">
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer"><span></span></div>
          <div class="imgContainer">
            <span></span>
            <!-- <a target="_blank" href="https://techsuppliers.com.ar/"> -->
            <!-- </a>   -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
};

</script>

<style scoped lang="sass">
.ourAlliesComponent
  display: flex
  width: 100%
  padding: 3rem 0
.container
  width: 100%
  padding: 2rem 1.5rem 0
h1
  text-align: center
  margin: 1rem 0px 2rem
.imgRow
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin: 1rem 0 1.5rem
  position: relative
.imgContainer 
  flex-basis: 50%
  box-sizing: border-box
  padding: 5px 0
  margin-top: 0.25rem
  span
    display: block 
    height: 50px
    opacity: 0.6
    width: 100px
    margin: 0 auto
    background-image: url(../assets/partners/a08.png)
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    // filter: grayscale(1) brightness(20)
    opacity: 1
  &:nth-child(odd)
    padding-left: 0
  &:nth-child(even)
    padding-right: 0
  &:nth-child(2)
    span
      background-image: url(../assets/partners/a02.png)
  &:nth-child(3)
    span
      background-image: url(../assets/partners/a03.png)
  &:nth-child(4)
    span
      background-image: url(../assets/partners/a01.png)
  &:nth-child(5)
    span
      background-image: url(../assets/partners/a05.png)
  &:nth-child(6)
    span
      background-image: url(../assets/partners/a06.png)
  &:nth-child(7)
    span
      background-image: url(../assets/partners/a07.png)
  &:nth-child(8)
    span
      background-image: url(../assets/partners/a04.png)
  &:nth-child(9)
    span
      background-image: url(../assets/partners/a09.png)
  &:nth-child(10)
    span
      background-image: url(../assets/partners/a10.png)
  &:nth-child(11)
    span
      background-image: url(../assets/partners/a11.png)
  &:nth-child(12)
    span
      background-image: url(../assets/partners/a12.png)
  &:nth-child(13)
    span
      background-image: url(../assets/partners/a13.png)
  &:nth-child(14)
    span
      background-image: url(../assets/partners/a14.png)
  &:nth-child(15)
    span
      background-image: url(../assets/partners/a15.png)
  &:nth-child(16)
    span
      background-image: url(../assets/partners/a16.png)
  &:nth-child(17)
    span
      background-image: url(../assets/partners/a17.png)
  &:nth-child(18)
    span
      background-image: url(../assets/partners/a18.png)
  &:nth-child(19)
    span
      background-image: url(../assets/partners/a19.png)
  &:nth-child(20)
    span
      background-image: url(../assets/partners/a23.svg)
  &:nth-child(21)
    span
      background-image: url(../assets/partners/a21.png)
  &:nth-child(22)
    span
      background-image: url(../assets/partners/a22.png)
  &:nth-child(23)
    span
      background-image: url(../assets/partners/a24.svg)
@media (min-width: 768px)
  .container 
    max-width: var(--breakpoint-xl)
    margin: 0 auto
    padding: 2rem var(--container-space) 0
  .imgRow
    &:after
      display: flex
      content: ''
      height: 1px
      width: 50%
      background-color: rgba(0,0,0,.15)
      align-self: center
  .imgContainer
    flex-basis: 25%
    margin: 1.5rem 0
    padding: 0 1rem
    padding-left: 1rem
    &:nth-child(odd)
      padding-left: 1rem
    &:nth-child(even)
      padding-right: 1rem
    span
      max-width: 130px
      width: 100%
      margin: 0 auto
    // &:last-child:after
    //   display: flex
    //   content: ''
    //   height: 1px
    //   width: 100%
    //   background-color: red
  .imgRow
    margin-bottom: 0
    justify-content: flex-start 
</style>
