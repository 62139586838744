<template>
  <div class="view">
    <div class="container">
      <div class="row">
        <p class="lead">{{ $t('background.text1') }}</p>
      </div>
      <div class="row">
        <p class="lead">{{ $t('background.text2') }}</p>
      </div>
      <div class="row">  
        <p class="lead">{{ $t('background.text3') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
// import ...
  export default {};
</script>

<style scoped lang="sass">
.view
  padding: 3rem 0
  display: flex
  flex-direction: column
  background-image: url("../../assets/about/backgroundbg.png")
  background-size: cover
  background-position: center bottom
  background-blend-mode: multiply
  background-blend-mode: soft-light
  background-color: var(--color-contrast-lower)
.container
  padding: 2rem var(--section-space)
  width: 100%
  box-sizing: border-box
.row
  display: flex
  justify-content: flex-start
  align-items: center
  margin-bottom: 1rem
p
  margin-bottom: 1rem
  text-align: center

@media (min-width: 768px)
  .container
    padding-top: 0
  .view
    // padding: 2rem 0
  p
    // flex-basis: calc(50% - 1rem)
    box-sizing: content-box
    text-align: left
    // padding-left: 1rem
  .row
    flex-direction: row
    margin-bottom: 0
  p.lead
    font-size: var(--font-size-md)
    line-height: 2.5rem
    max-width: 820px
</style>