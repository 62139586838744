<template>
  <div class="view --news">
    <!-- <navBlog></navBlog> -->
    <div class="container" v-if="posts">
      <Post v-for="(post, index) in posts"
            :post="post"
            :key="'post' + index" />
      <infinite-scroll v-if="nextPage" @show-more="getMore" />
    </div>
    <div v-if="isLoading" class="loader"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
  </div>
</template>

<script>
import Post from '@/components/Post'
import InfiniteScroll from '@/components/InfiniteScroll'
import { getPosts } from '@/helpers/api'
// import navBlog from '@/components/navBlog'

export default {
  name: "BlogList",
  components: {
    Post,
    InfiniteScroll,
    // navBlog
  },
  watch: {
    lang: {
      handler() {
        this.posts = []
        this.nextPage = null
        this.getMore()
      }
    }
  },
  data () {
    return {
      posts: null,
      nextPage: null,
      isLoading: true
    }
  },
  methods: {
    getMore: function () {
      this.isLoading = true
      getPosts(this.$i18n.locale, this.nextPage)
        .then((response) => {
          this.posts = this.posts.concat(response.data.items)
          this.nextPage = response.data.nextPageToken
        })
        .finally(() => { this.isLoading = false })

    }
  },
  computed: {
    lang: function () {
      return this.$i18n.locale
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getPosts(this.$i18n.locale)
      .then((response) => {
        this.posts = response.data.items
        this.nextPage = response.data.nextPageToken
      })
      .finally(() => { this.isLoading = false })
  }
};
</script>

<style lang="sass" scoped>
@import '../assets/colors.sass'
.view
  display: flex
  flex-direction: column
  margin: 0 auto
  width: 100%
.loader
  display: flex
  align-items: center
  justify-content: center
  margin-top: 4rem
.container
  padding: 2rem var(--section-space) 0
  box-sizing: border-box
.articleRow
  width: 100%
  flex-direction: column
  box-sizing: border-box
  border: 1px solid #313131
  background-color: var(--color-contrast-lower)
.articleRow__pagination
  display: flex
  justify-content: center
  align-items: center
  font-size: 1rem
  margin-top: 4rem
.articleRow__paginationItem
  margin: 0 0.25rem
  padding: 0.25rem 0.75rem
  box-shadow: 0 4px 10px -5px rgba(0,0,0,0.1)
  border-radius: 3px
  cursor: pointer
  color: var(--color-contrast-low)
  transition: color 0.3s ease, background-color 0.3s ease
  background-color: #FFF
  &:hover
    color: var(--color-contrast-medium)
    background-color: var(--color-contrast-lower)
.articleRow__image
  width: auto
@media (min-width: 768px)
  .container
    max-width: var(--breakpoint-lg)
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
  .articleRow
    flex-direction: row
</style>
