<template>
  <div class="view">
    <div class="container">
      <ValidationObserver ref="observer-new-agency" v-slot="v">
        <form :action="form_url" class="formContainer" @submit.prevent="on_submit" method="post">

          <div class="inputText">
            <label class="inputText__label" for="agency_name">{{ $t("form.agencyName") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="agency_name" name="agency_name" :placeholder="$t('form.enter')+' '+$t('form.agencyName')" v-model="form_data.agency_name">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="address">{{ $t("form.address") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="address" name="address" :placeholder="$t('form.enter')+' '+$t('form.address')" v-model="form_data.address">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="location">{{ $t("form.location") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="location" name="location" :placeholder="$t('form.enter')+' '+$t('form.location')" v-model="form_data.location">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="state_prov">{{ $t("form.state_prov") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="state_prov" name="state_prov" :placeholder="$t('form.enter')+' '+$t('form.state_prov')" v-model="form_data.state_prov">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="country">{{ $t("form.country") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="country" name="country" :placeholder="$t('form.enter')+' '+$t('form.country')" v-model="form_data.country">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="phone">{{ $t("form.phone") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="phone" name="phone" :placeholder="$t('form.enter')+' '+$t('form.phone')" v-model="form_data.phone">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="contact">{{ $t("form.contact") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="contact" name="contact" :placeholder="$t('form.enter')+' '+$t('form.contact')" v-model="form_data.contact">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="email">{{ $t("form.eMail") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true, email: true }" v-slot="{ errors }">
              <input class="inputText__input" type="email" id="email" name="email" :placeholder="$t('form.enter')+' '+$t('form.eMail')" v-model="form_data.email">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText">
            <label class="inputText__label" for="IATA">{{ $t("form.iata_no") }}<em class="muted"> ({{ $t("form.if_you_have_it") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: false }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="IATA" name="IATA" :placeholder="$t('form.enter')+' '+$t('form.iata_no')+' '+$t('form.if_you_have_it')" v-model="form_data.IATA">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText --platform">
            <label class="inputText__label">{{ $t("form.platforms_requested") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>

            <ValidationObserver class="inputText__group" ref="observer-platforms" v-slot="platforms_vslot">
              <div v-for="platform in form_data.platforms" :key="platform.id">

                <ValidationProvider class="inputCheckbox" :name="platform.id" :rules="{ atLeastOnePlatform: true }" v-slot="{ errors }">
                  <label :for="platform.id" class="inputCheckbox__label">
                    <input class="inputCheckbox__input" ref="platform" type="checkbox" name="platform[]" :value="platform.id" :id="platform.id" v-model="platform.checked">
                    <div class="inputCheckbox__check"><i>✓</i></div>
                    {{ platform.name }}
                  </label>
                </ValidationProvider>

              </div>
              <em class="inputText__feedback">{{ showPlatformsError(platforms_vslot) }}</em>
            </ValidationObserver>

          </div>

          <div class="inputText">
            <label class="inputText__label" for="users_qty">{{ $t("form.users_qty") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
              <input class="inputText__input" type="text" id="users_qty" name="users_qty" :placeholder="$t('form.enter')+' '+$t('form.users_qty')" v-model="form_data.users_qty">
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText --textarea">
            <label class="inputText__label" for="observation">{{ $t("form.observations") }}:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: false }" v-slot="{ errors }">
              <textarea class="inputText__input" id="observation" rows="5" name="observation" :placeholder="$t('form.your')+' '+$t('form.observations')" v-model="form_data.observation"></textarea>
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <div class="inputText --emailCLK --textarea">
            <label class="inputText__label" for="email_kiu_click">{{ $t("form.access_email_kiuclick") }} <em class="muted">({{ $t("form.if_you_request_kiuclick") }})</em>:</label>
            <ValidationProvider class="inputText__group" :rules="{ required: false }" v-slot="{ errors }">
              <textarea class="inputText__input" id="email_kiu_click" rows="5" name="email_kiu_click" placeholder="Administrator: Other users:" v-model="form_data.email_kiu_click"></textarea>
              <em class="inputText__feedback">{{ errors[0] }}</em>
            </ValidationProvider>
          </div>

          <input type="hidden" name="recaptcha_token" :value="recaptcha_token">
          <div class="btnContainer">
            <button class="btn btn-secondary --gradient --cta" type="submit" value="Submit"><span>{{ $t("form.send") }}</span></button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>


<script>
  /* eslint-disable */
  import Vue from "vue";
  import { VueReCaptcha } from "vue-recaptcha-v3";
  import Axios from 'axios';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';

  import MessageBoxMixin from '@/mixins/MessageBox/emitter';

  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_SITEKEY,
  });

  extend("required", {
    validate: value => !!(String(value).trim())
  });

  extend("atLeastOnePlatform", {
    validate: () => window.$platform_refs.some(x => x.checked),
  });

  export default {
    mixins: [ MessageBoxMixin ],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    computed: {
      current_language() {
        return this.$i18n.locale;
      },
    },
    watch: {
      current_language(new_val) {
        this.form_data.language = new_val;
      }
    },
    data() {
      return {
        // dummy_data_fill: Boolean(process.env.VUE_APP_DEBUG),
        dummy_data_fill: false,
        request_in_progress: false,

        validation_observer: null,
        // platforms_observer: null,
        recaptcha_token: "",
        form_url: `${process.env.VUE_APP_API}/new-agency`,
        form_data: {
          language: this.$i18n.locale,
          agency_name: "",
          address: "",
          location: "",
          state_prov: "",
          country: "",
          phone: "",
          contact: "",
          email: "",
          IATA: "",
          platforms: [
            { checked: false, id: "kiu_command", name: "KIU Command (Cryptic Solution)" },
            { checked: false, id: "kiu_click", name: "KIU Click (Graphic Solution)" },
          ],
          users_qty: "",
          observation: "",
          email_kiu_click: "",
        }
      }
    },
    created() {
      window.$platform_refs = null;
    },
    mounted() {
      window.$platform_refs = this.$refs["platform"];
      this.validation_observer = this.$refs['observer-new-agency'];

      this.form_data.language = this.$i18n.locale;

      if (this.dummy_data_fill) {
        this.fillFormData();
      }
    },
    methods: {
      fillFormData() {
        this.form_data = {
          language: this.$i18n.locale,
          agency_name: "Nombre de Agencia",
          address: "Dirección de prueba",
          location: "Ubicación de prueba",
          state_prov: "Estado/Prov.",
          country: "Argentina",
          phone: "4556-7789",
          contact: "Persona a contactar",
          email: "email@contacto.com",
          IATA: "7777",
          platforms: [
            { checked: true, id: "kiu_command", name: "KIU Command (Cryptic Solution)" },
            { checked: true, id: "kiu_click", name: "KIU Click (Graphic Solution)" },
          ],
          users_qty: "8",
          observation: "Observaciones de ejemplo",
          email_kiu_click: "email@kiuclick.com",
        };
      },
      resetForm() {
        this.form_data = {
          language: this.$i18n.locale,
          agency_name: "",
          address: "",
          location: "",
          state_prov: "",
          country: "",
          phone: "",
          contact: "",
          email: "",
          IATA: "",
          platforms: [
            { checked: false, id: "kiu_command", name: "KIU Command (Cryptic Solution)" },
            { checked: false, id: "kiu_click", name: "KIU Click (Graphic Solution)" },
          ],
          users_qty: "",
          observation: "",
          email_kiu_click: "",
        };
      },
      showPlatformsError(observer_vslot) {
        // window.console.info(observer_vslot);
        if (observer_vslot.pristine && !observer_vslot.validated) return "";

        let err_msg = "";
        let one_checked = this.form_data.platforms.some( x => x.checked );

        if (!one_checked) {
          for (let idx in observer_vslot.errors) {
            if (observer_vslot.errors[idx].length) {
              err_msg = observer_vslot.errors[idx][0];
              break;
            }
          }
          return err_msg;
        }
        return "";
      },
      async on_submit () {

        if (this.request_in_progress) {
          // window.console.warn("Petición en curso. Espere por favor.");
          this.error_messagebox(
                this.$t("formFeedback.requestinprogress.title"),
                this.$t("formFeedback.requestinprogress.message"),
                this.$t("formFeedback.btn_acc"));
          return;
        }
        // window.console.info("Tramitando token...");

        let validation_observer = this.validation_observer;
        const form_valid = await validation_observer.validate();

        if (!form_valid) {
          // this.error_messagebox("No se puede enviar el formulario", "Complete los campos correspondientes, por favor.", "Aceptar");
          window.console.warn("NO se puede enviar el formulario: hay campos incompletos.");
          return;
        }

        let ctx = this; // Guardo el contexto de "this" para poder usarlo dentro del closure al pegarle a Axios

        ctx.form_data.selected_platforms = [];
        ctx.form_data.platforms.forEach(x => {
          if (x.checked) {
            ctx.form_data.selected_platforms.push({ id: x.id, name: x.name });
          }
        });

        ctx.request_in_progress = true; // No se puede apretar el botón de nuevo

        this.recaptcha((token) => {
          let form_data = ctx.form_data;
          form_data.recaptcha_token = token;

          window.console.warn("=== FORM DATA ===");
          window.console.warn(form_data);
          window.console.warn("===/FORM DATA ===");

          Axios.post(ctx.form_url, form_data, { "headers": { "Content-Type": "application/json" } }).then((response) => {
            window.console.warn("--- RESPONSE---");
            window.console.warn(response.data);

            if (response.data.success) {
              ctx.success_messagebox(undefined, ctx.$t("agencies.msg_newAgencySuccess"), undefined, {
                on_close: () => {
                  ctx.resetForm();
                  ctx.validation_observer.reset();
                }
              });
            } else {
              ctx.error_messagebox();
            }

            window.console.warn("---/RESPONSE---");
          }).catch((reason) => {
            // ctx.error_messagebox("Error al realizar la petición", reason, "Aceptar");
            ctx.error_messagebox();
          }).finally(() => {
            // window.console.warn("ctx.request_in_progress = false;");
            ctx.request_in_progress = false;
          });

          return false;
        });

        return false;
      },
      async recaptcha(callback) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login');

        callback(token);
      },
    }
  };
</script>

<style scoped lang="sass">
.view
  padding-bottom: 4rem
.formContainer
  grid-template: 1fr / 1fr
  padding-top: 3rem
  gap: 1rem
  display: flex
  flex-direction: column
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
.inputText
  &.--emailCLK
    grid-column: 1/4
  &.--platform, &.--platform .inputText__group
    gap: 1rem
    display: flex
    flex-direction: column
    height: auto
@media (min-width: 996px)
  .formContainer
    display: grid
    grid-template: repeat(3, 1fr) / repeat(3, 1fr)
</style>
