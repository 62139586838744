import Vue from 'vue';

import UUID from 'vue-uuid';
Vue.use(UUID);

import VueSession from 'vue-session';
let vuesession_options = {
    persist: true
};
Vue.use(VueSession, vuesession_options);

import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faChevronRight, faMapMarkerAlt, faDownload, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import i18n from './locales/i18n';
import checkView from 'vue-check-view';

import gsap from 'gsap';
Vue.use(gsap);

// import { faFacebook } from '@fortawesome/free-brand-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AnchorRouterLink from 'vue-anchor-router-link';

import "@fontsource/lexend-deca" // Defaults to weight 400
import "@fontsource/lexend-deca/200.css" // Specify weight
import "@fontsource/lexend-deca/300.css" // Specify weight
import "@fontsource/lexend-deca/400.css" // Specify weight
// import "@fontsource/lexend-deca/400-italic.css";


import router from './router';

Vue.use(checkView);

library.add(faBars, faChevronRight, faMapMarkerAlt, faDownload, faCaretDown);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// import { localize } from 'vee-validate/dist/vee-validate.full';

window.$app = new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');

Vue.use(AnchorRouterLink, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 });
