<template>
  <div class="view">
    <div class="container --bg">
      <h1>{{ $t('philosophy.titleMision') }}</h1>
      <div class="philosophy__content">
        <p class="lead">{{ $t('philosophy.textMision') }}</p>
      </div>
      <h1>{{ $t('philosophy.titleVision') }}</h1>
      <div class="philosophy__content">
        <p class="lead">{{ $t('philosophy.textVision') }}</p>
      </div>
    </div>
    <div class="container">
      <div class="ourValues">      
        <div class="ourValues__value">
          <div class="ourValues__img">
            <img src="../../assets/about/value1.png" alt="">
          </div>
          <div class="ourValues__valueContainer">
            <div class="ourValues__header">
              <span>{{ $t('philosophy.emphaty') }}</span>
            </div>
            <div class="ourValues__text">{{ $t('philosophy.emphatyText') }}</div> 
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__img val2">
            <img src="../../assets/about/value2.png" alt="">
          </div>
          <div class="ourValues__valueContainer">
            <div class="ourValues__header">
              <span>{{ $t('philosophy.passion') }}</span>
            </div>
            <div class="ourValues__text">{{ $t('philosophy.passionText') }}</div> 
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__img val3">
            <img src="../../assets/about/value3.png" alt="">
          </div>
          <div class="ourValues__valueContainer">
            <div class="ourValues__header">
              <span>{{ $t('philosophy.responsability') }}</span>
            </div>
            <div class="ourValues__text">{{ $t('philosophy.responsabilityText') }}</div> 
          </div>
        </div> 
        <div class="ourValues__value">
          <div class="ourValues__img val4">
            <img src="../../assets/about/value4.png" alt="">
          </div>
          <div class="ourValues__valueContainer">
            <div class="ourValues__header">
              <span>{{ $t('philosophy.resilence') }}</span>
            </div>
            <div class="ourValues__text">{{ $t('philosophy.resilenceText') }}</div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import ...
  export default {};
</script>

<style scoped lang="sass">
.view
  // padding-bottom: 4rem
  position: relative
  z-index: 2
  display: flex
  flex-direction: column
  background-image: url("../../assets/about/philosophybg.png")
  background-size: cover
  background-repeat: no-repeat
  // background-blend-mode: multiply
  background-blend-mode: soft-light
  background-color: var(--color-contrast-lower)
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
  width: 100%
  &.--bg
    background: linear-gradient(180deg, black, transparent)
    padding: 0
    max-width: initial
.lead
  text-align: center
h1
  text-align: center
  margin: 2rem 0 1.25rem
.philosophy__content
  width: 100%
  margin: 0 auto
  padding: 0 var(--section-space)
  box-sizing: border-box
  .lead
    line-height: 1.7rem
.ourValues
  display: flex
  flex-direction: column
  gap: 1rem
  padding-bottom: 5rem
.ourValues__value
  flex: 1
  width: 100%
  padding: 0
  box-sizing: border-box
  position: relative
  display: flex
  justify-content: flex-start
  flex-direction: column
  align-items: center
.ourValues__valueContainer
  display: flex
  flex-direction: column
  padding: 4rem 1.5rem 1.5rem
  background-color: var(--color-contrast-lower)
  border-radius: var(--b-radius-xl)
  border: 1px solid black
  box-shadow: var(--z-2)
  margin: 0 auto
  flex: 1
  gap: 1rem
  // margin-right: 0
.ourValues__header
  position: relative
  display: flex
  justify-content: center
  flex-direction: row
  font-size: var(--font-size-lg)
.ourValues__text
  font-size: var(--font-size-base)
  text-align: center
  margin: 0 auto
  margin-right: 0
  line-height: 1.75rem
  max-width: 450px
.ourValues__img
  background-image: linear-gradient(to bottom right, #a18cd1 0%, #fbc2eb 100%)
  padding: 2rem
  border-radius: 50%
  display: flex
  animation: filter-animation 8s infinite
  transform: translateY(3rem)
  img 
    display: flex
    height: auto
    mix-blend-mode: overlay
    width: 80px
    height: 80px
  &.val2
    background-image: linear-gradient(120deg, #e71d7d 0%, #ca96e6 100%)
  &.val3
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%)
  &.val4
    background-image: linear-gradient(to top, #d299c2 0%, #fef9d7 100%)
@media (min-width: 768px)
  .view
    padding: 0
  .philosophy__content
    width: 800px
    padding: 0
  .ourValues__value
    // flex: 1
    // flex-basis: 50%
    // max-width: 48%
    // padding: 1.5rem
  .ourValues
    display: grid
    grid-template: 1fr 1fr / 1fr 1fr
  .ourValues__img
    img
      width: 80px

@keyframes filter-animation
  0%
    filter: hue-rotate(0deg)
  
  50%
    filter: hue-rotate(100deg)
  
  100%
    filter: hue-rotate(0deg)
</style>