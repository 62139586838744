import { render, staticRenderFns } from "./ourAllies.vue?vue&type=template&id=294e95f8&scoped=true"
import script from "./ourAllies.vue?vue&type=script&lang=js"
export * from "./ourAllies.vue?vue&type=script&lang=js"
import style0 from "./ourAllies.vue?vue&type=style&index=0&id=294e95f8&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294e95f8",
  null
  
)

export default component.exports