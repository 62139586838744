<template>
    <div class="view">
        <div class="container">
            <br>
            <p class="lead">
                <span style="font-size: 1.5em;">Para confirmar la operación haga click en <strong>Aceptar</strong></span><br>
                <em>
                    <i>(Si desea cancelar, puede cerrar esta pestaña)</i>
                </em>
            </p>
            <br>
            <p class="lead">
                <button class="btn btn-secondary --gradient" @click.prevent="reset_device" style="display: inline-block;">
                    <span>Aceptar</span>
                </button>
            </p>

        </div>
        <formFeedback ref="form-feedback" v-if="form_feedback.active"
                      :title="form_feedback.title" :message="form_feedback.message"
                      :button="{ caption: form_feedback.button_caption }"
                      @close_messagebox="form_feedback.active = false"
        />
    </div>
</template>


<script>
    /* eslint-disable */
    import Axios from 'axios';
    import formFeedback from "@/components/MessageBox";

    export default {
        components: {
            formFeedback
        },
        data() {
            return {
                form_feedback: {
                    $ref: null,
                    active: false,
                    title: "",
                    message: "",
                    button_caption: "",
                },
                form_url: `${process.env.VUE_APP_API}/kiu-ws-command/reset-device-confirm`,
                form_data: {
                    language: this.$i18n.locale,
                    id: null,
                    token: null,
                    ip: null
                }
            }
        },
        created() {
            this.form_data.token = this.$session.id().replace("sess:", "");
        },
        mounted() {
            let oClientIP = document.querySelector("#client_ip");
            let oContext = this;

            if (oClientIP.value) {
                // window.console.log("Got the IP at first attempt: " + oClientIP.value);
                this.form_data.ip = oClientIP.value;
            } else {
                // window.console.log("No IP yet...");
                window._interv_getIP = window.setInterval(function () {
                    let oClientIP = document.querySelector("#client_ip");

                    if (oClientIP.value) {
                        // window.console.log("Got the IP after: " + oClientIP.value);
                        oContext.form_data.ip = oClientIP.value;
                        window.clearInterval(window._interv_getIP);
                    }
                }, 100);
            }

            this.form_data.id = this.$route.params.id;

            // window.console.warn("===  MOUNTED: DEVICE RESET CONFIRM ===");
            // window.console.warn(this.form_data);
            // window.console.warn("=== /MOUNTED: DEVICE RESET CONFIRM ===");
        },
        methods: {
            giveFeedback(title, message, button_caption) {
                this.form_feedback['title'] = title;
                this.form_feedback['message'] = message;
                this.form_feedback['button_caption'] = button_caption;
                this.form_feedback['active'] = true;
            },
            giveSuccessFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.success.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.success.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            giveErrorFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.error.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.error.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            reset_device() {
                let ctx = this;

                Axios.post(this.form_url, this.form_data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then((response) => {
                    window.console.warn("--- RESPONSE---");
                    window.console.warn(response.data);
                    window.console.warn("---/RESPONSE---");

                    let data = response.data;

                    if (data.success) {
                        ctx.giveSuccessFeedback(ctx.$t('agencies.success'), ctx.$t('agencies.msg_deviceResetSuccess'), ctx.$t('agencies.accept'));
                    } else {
                        ctx.giveErrorFeedback(data.message, ctx.$t('agencies.msg_deviceResetError'), ctx.$t('agencies.accept'));
                    }
                });

                return false;
            }
        },
    };
</script>

<style scoped lang="sass">
    em
        font-style: italic

        .muted
            color: #777

        .view
            padding-bottom: 2rem

        .tabPanelContent
            display: block
            box-shadow: 0 7px 30px -13px rgba(0, 0, 0, 0.5)
            border-radius: 10px 10px var(--borderRadius-d1) 10px
            max-width: 960px
            margin: 0 auto
            margin-top: 3rem

        .inputComment
            display: block
            line-height: 0.8rem
            margin-top: 3px
            font-weight: 600

        @media (min-width: 768px)
            .formContainer
                flex-wrap: wrap
                flex-direction: row
                justify-content: space-between
                align-items: flex-start

                .inputContainer
                    flex-basis: 50%
                    box-sizing: border-box
                    max-width: 48%

                .inputContainer:nth-child(3)
                    flex-basis: 100%
                    max-width: initial

                button
                    margin-left: auto
                    justify-self: flex-end
</style>
