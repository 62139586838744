<template>
  <div class="container --fluid">
    <hooper
      :itemsToShow="1"
      :itemsToSlide="1"
      :infiniteScroll="true"
      :wheelControl="false"
      :playSpeed="5000"
      :autoPlay="true"
      :hoverPause="false"
      :transition="700"
      >
      <slide>
        <div class="sliderCaption --slideVideo">
          <video-bg :sources="['videos/team.webm']" img="videos/team.jpg">
            <div class="hero__container">            
              <h1 class="sliderTitle">{{ $t('heroSlider.title2') }}</h1>
              <router-link to="/about/background" tag="button" class="btn btn-secondary --gradient"><span>{{ $t('heroSlider.btn2') }}</span></router-link>
            </div>
          </video-bg>
        </div>
      </slide>
      <slide>
        <div class="sliderCaption --slide1">
          <div class="hero__container">
            <h1 class="sliderTitle">{{ $t('heroSlider.title1') }}</h1>
            <router-link to="/solutions" tag="button" class="btn btn-secondary --gradient"><span>{{ $t('heroSlider.btn1') }}</span></router-link>
          </div>
        </div>
      </slide>
      <slide>
        <div class="sliderCaption --slide2">
          <div class="hero__container">
            <h1 class="sliderTitle">{{ $t('heroSlider.title3') }}</h1>
            <router-link to="/solutions/pss" tag="button" class="btn btn-secondary --gradient"><span>{{ $t('heroSlider.btn3') }}</span></router-link>
          </div>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
    <!-- <alertNews v-if="post" :post="post"></alertNews> -->
  </div>
</template>

<script>

// import AlertNews from '@/components/alertNews.vue'
import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css';
import VideoBg from 'vue-videobg'

export default {
  name: 'heroSection',
  props: ['post'],
  components: {
    // AlertNews,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    VideoBg
  },
};
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
@media (min-width: 768px) and (max-width: 1200px)
  .sliderTitle
    max-width: 90%

.container
  background-image: url("../assets/hero.png")
  height: 650px
  background-size: cover
  background-position: center center
  box-sizing: border-box
  padding: 0
  justify-content: flex-start
  align-items: flex-start
  border-radius: var(--borderRadius-sections)
  position: relative
.alertNews
  position: absolute
  bottom: -30px
  left: 0
  right: 0
  margin: auto
  // width: calc(100% - 1rem)
  width: 100%
  border-radius: 0
.sliderTitle
  color: var(--color-white)
  font-size: var(--font-size-xl)
  text-align: center
  padding: 0 1rem
  max-width: 790px
.btn
  // margin-top: 1.5rem
.lead
  color: var(--color-white)
  margin-left: 0
.hooper-pagination
  position: absolute
  width: 40%
  display: none
  bottom: 0
  right: initial
  left: var(--container-space)
  transform: none
  outline: none
  transform: skewX(44deg)
  border-top: 1px solid var(--color-terciary)
  border-right: 2px solid var(--color-terciary)
  // border-top-right-radius: var(--b-radius-lg)
  padding: 0
.hooper
  height: 100%
  border-radius: var(--borderRadius-sections)
  overflow: hidden
  outline: none
.hooper-slide
  width: 100%
.sliderCaption
  width: 100%
  padding: 0
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  background-position: center
  background-size: cover
  background-color: var(--color-primary)
  justify-content: flex-start
  padding-top: 7.5rem
  p
    text-align: left
    padding-right: 2rem
  .btn
    padding: 0.5rem 3rem
    // padding: 0.75rem 4rem
    // width: 100%
    // max-width: 300px
.--slide1
  background-image: url("../assets/hero.png")
.--slide2
  background-image: url("../assets/hero2.png")
.--slideVideo
  padding: 0
.VideoBg
  width: 100%
  height: 100% !important
::v-deep .VideoBg__content
  align-items: center
.hero__container
  max-width: var(--breakpoint-xl)
  width: 100%
  margin: 0 auto
@media (min-width: 768px)
  .hooper-pagination
    display: flex
  .hero__container
    margin: 0
    .btn
      padding: 0.75rem 4rem
  .container
    height: 90vh
    max-height: 650px
    background-image: url("../assets/herolg.jpg")
    align-items: flex-start
    justify-content: center
    text-align: left
    border-radius: 0 0 0 0
  .--slide1
    background-image: url("../assets/herolg.jpg")
  .--slide2
    background-image: url("../assets/hero2lg.jpg")
  .alertNews
    left: initial
    right: var(--container-space)
    border-radius: var(--b-radius-lg)
  .sliderTitle
    font-size: var(--font-size-d1)
    letter-spacing: 2px
    padding-top: inherit
    text-align: left
    padding-left: 0
    padding-right: 2rem
    // width: 80%
  .sliderCaption
    justify-content: center
    padding-top: 0
    // align-items: flex-start
@media (min-width: 1500px)
  .sliderTitle
    font-size: 2.5rem
    max-width: 690px
  .hero__container
    // max-width: initial
    width: 100%
    justify-content: flex-start
    align-items: flex-start
</style>
<style scoped lang="sass">
.hero__container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 1rem
@media (min-width: 992px)
  .hero__container
    justify-content: flex-start
    align-items: flex-start
    gap: 2rem
</style>
