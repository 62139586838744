<template>
  <div class="container --fluid" v-view="homeNewsAnimation">
    <div class="homeNews container">
      <!-- <h1>{{ $t('homeNews.title') }}</h1> -->
      <!-- <navBlog class="navBlog"></navBlog> -->
      <div class="homeNewsMask">
        <div class="homeNewsCarousel">
          <div class="articleCardContainer" v-for="(post, index) in posts" :key="'article-card' + index">
            <article-card :post="post"></article-card>
          </div>
        </div>
      </div>
      <router-link to="/News" tag="button" class="btn btn-terciary --cta"><span>{{ $t('homeNews.btn') }}</span></router-link>
    </div>
  </div>
</template>

<script>
import articleCard from './articleCard'
import { TimelineMax } from 'gsap';
// import navBlog from '@/components/navBlog'

export default {
  props: ['posts'],
  components: {
    articleCard,
    // navBlog
  },
  data() {
    return {
      animated: false,
      homeNewsTl: new TimelineMax({paused: true})
    }
  },
  methods: {
    homeNewsAnimation: function(e) {
      if((e.type == 'enter' || e.type == 'progress') && e.percentInView > 0.5 && !this.animated) {
        this.homeNewsTl.play(0);
        this.animated = true;
      }
    }
  },
  mounted() {
      this.homeNewsTl.staggerFrom(".articleCard", 0.3, {scaleX:0, alpha: 0}, 0.2, "curtains")
      .staggerTo(".articleCardCurtain", 0.3, {scaleX:0}, 0.2, "curtains+=0.5")
      .staggerFrom(".articleTitle p", 0.3, {alpha:0, x:-40}, 0.2, "curtains+=0.5")
      .staggerFrom(".articleCard button", 0.3, {alpha:0, y:-60}, 0.2, "curtains+=0.65")
      // .staggerFrom(".feature", 1,{x:20, attr:{width:500}, alpha: 0}, 0.5, 'tito')
      ;
    }
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.homeNews
  // display: flex
  justify-content: flex-end
  // flex-direction: column
  // margin: 0 auto
  // max-width: var(--breakpoint-xl)
  padding: 0 0 2rem
  justify-content: flex-end
  align-items: center
  .btn
    transform: translateY(1rem)
.articleCardContainer
  gap: 2rem
  overflow: hidden
  display: flex
  min-width: 360px
.container.--fluid
  padding: 0
  background: linear-gradient(180deg, transparent 50%, hsla(263deg, 69%, 57%, .42) 50%, var(--color-primary) 75%)
  background-color: transparent
  position: relative
  z-index: 6
  border-radius: var(--borderRadius-sections)
.homeNews
h1
  text-align: center
  color: var(--color-white)
.homeNewsMask
  width: 100%
  overflow: hidden
  box-sizing: border-box
.homeNewsCarousel
  padding: 0 1rem .75rem 1rem
  margin-bottom: .75rem
  display: flex
  overflow-x: scroll
  gap: 1rem
@media (min-width: 768px)

  .homeNewsCarousel
    overflow: auto
  .homeNewsMask
    box-sizing: border-box
  .btn.--outline
    margin-right: calc(1.5rem + var(--container-space))
  .articleCardContainer
    min-width: initial
    width: 100%
@media (min-width: 992px)
  .homeNews
    padding: 4rem 0
  .homeNewsCarousel
    justify-content: center
    padding: 0
</style>
