<template>
  <div class="containerFluid">
    <div class="container">
      <div class="footerComponent">
          <div class="brands">
            <div class="kiuLogo">
              <img class="logo" src="../assets/footer/footer.svg" alt="">
              <p>{{ $t('footer.logoLegend') }}</p>
            </div>
          </div>
          <div class="newsletter">
            <ValidationObserver class="newsletter__container" ref="observer-newsletter" v-slot="v">
              <form class="newsletter__form"  :action="form_url" method="POST" @submit.prevent="on_submit" ref="form-newsletter">
  <!--               <div class="inputContainer">
                  <label for="subscriber_name">{{ $t('footer.subscriber_name') }}</label>
                  <input type="text" name="subscriber_name" id="subscriber_name" v-model="form_data.subscriber_name"
                         :placeholder="$t('footer.subscriber_name')" />
                </div> -->
                <div class="inputText">
                  <!-- <label for="subscriber_name">{{ $t('footer.subscriber_email') }}</label> -->
                  <ValidationProvider class="inputText__group" :rules="{ required: true, email: true }" v-slot="{ errors }">
                    <input class="inputText__input" type="text" name="subscriber_name" id="subscriber_email" v-model="form_data.subscriber_email"
                           :placeholder="$t('footer.subscriber_email')" @keydown="on_keydown" />
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div>

                <button class="btn btn-terciary --gradient" type="submit" value="Submit">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" size="lg"/>
                    <!--{{ $t('form.add_to_newsletter') }}-->
                </button>
                <!-- <div class="clearfix"></div> -->
              </form>
            </ValidationObserver>
          </div>

          <div class="contactInfo">
            <p>{{ $t('footer.adress01') }}</p>
            <p>{{ $t('footer.adress02') }}</p>
            <p>{{ $t('footer.adress03') }}</p>
          </div>

          <div class="socialMedia">
            <a class="btnGlyph" href="https://www.facebook.com/kiusys/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
            </a>
            <a class="btnGlyph" href="https://www.linkedin.com/company/kiu-system-solutions/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
            </a>
            <a class="btnGlyph" href="https://www.instagram.com/kiusystem/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"/></svg>
            </a>
          </div>





      </div>
      <div class="siteMap">
        <ul>
          <router-link to="/solutions" tag="li">{{ $t('nav.solutions') }}</router-link>
          <router-link v-for="solution in solutions"
                       :to="'/solutions/' + solution.key"
                       :key="solution.key"
                       tag="li">
            <span>{{ solution.name }}</span>
          </router-link>
        </ul>
        <ul>
          <router-link to="/agencies" tag="li">{{ $t('nav.agencies') }}</router-link>
          <anchor-router-link
            :to="{name: 'agencies', hash: '#downloadGDS'}"
            :scrollOptions="{
            }"
          >{{ $t('footer.downloadGDS') }}</anchor-router-link>
          <router-link to="/agencies/new-agency" tag="li">{{ $t('footer.newAgency') }}</router-link>
          <router-link to="/agencies/device-reset" tag="li">{{ $t('footer.deviceReset') }}</router-link>
          <router-link to="/agencies/user-reset" tag="li">{{ $t('footer.userReset') }}</router-link>
        </ul>
        <ul>
          <router-link to="/about" tag="li">{{ $t('nav.about') }}</router-link>
          <router-link to="/about/philosophy" tag="li">{{ $t('footer.phylosophy') }}</router-link>
          <router-link to="/about/background" tag="li">{{ $t('footer.background') }}</router-link>
          <router-link to="/about/board" tag="li">{{ $t('footer.experts') }}</router-link>
        </ul>
        <ul>
          <router-link to="/news" tag="li">{{ $t('nav.news') }}</router-link>
        </ul>
        <ul>
          <router-link to="/contact" tag="li">{{ $t('nav.contact') }}</router-link>
        </ul>
      </div>
      <div class="sponsors">
        <img src="../assets/footer/iata-2.png" width="85" height="85" alt="">
        <!-- <img src="../assets/footer/iata.png" width="85" height="85" alt=""> -->
        <img src="../assets/footer/pcidss.png" width="85" height="auto" alt="">
      </div>
    </div>
    <img class="viewSeparator" src="../assets/section-separator-foot.svg" alt="">
    <div class="footerLegend">
      <span>KIU System Solutions {{ new Date().getFullYear() }} ®. {{ $t('footer.reserved') }} v{{ version }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';
import AnchorRouterLink from 'vue-anchor-router-link';
import MessageBoxMixin from "../mixins/MessageBox/emitter";
import Axios from "axios";
import solutions from '@/helpers/solutions'
import { version } from '../../package'

extend("required", {
  validate: value => !!(String(value).trim())
});

export default {
  name: 'footerComponent',
  mixins: [ MessageBoxMixin ],
  components: {
    AnchorRouterLink,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    current_language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    current_language(new_val) {
      this.form_data.language = new_val;
    }
  },
  data() {
    return {
      // dummy_data_fill: Boolean(process.env.VUE_APP_DEBUG),
      dummy_data_fill: false,
      request_in_progress: false,
      form_url: `${process.env.VUE_APP_API}/newsletter/subscribe`,
      form_data: {
        language: "",
        subscriber_email: "",
      },
      validation_observer: null,
      version: version,
      solutions
    }
  },
  mounted() {
    this.form_data.language = this.$i18n.locale;
    this.validation_observer = this.$refs['observer-newsletter'];
  },
  methods: {
    resetForm() {
      this.form_data = {
        language: this.$i18n.locale,
        subscriber_email: "",
      };
    },
    on_keydown(ev) {
      let ctx = this;
      // window.console.warn(ev.target);

      if (ev.key === "Escape") {
        ev.target.blur();
        this.resetForm();

        /**
         * this.$nextTick() se ejecuta pero no resetea el estado de error del form. Es un issue de la librería.
         * Workaround: Utilizar "window.setTimeout()" o bien, "requestAnimationFrame()".
         *
         * @see https://github.com/logaretm/vee-validate/issues/1976
         * */
        requestAnimationFrame(() => {
          ctx.validation_observer.reset();
        });
      }
    },
    async on_submit()
    {
      let ctx = this; // Me guardo el contexto de "this".

      if (ctx.request_in_progress) {
        // window.console.warn("Petición en curso. Espere por favor.");
        ctx.error_messagebox(
                ctx.$t("formFeedback.requestinprogress.title"),
                ctx.$t("formFeedback.requestinprogress.message"),
                ctx.$t("formFeedback.btn_acc"));
        return;
      }
      // window.console.info("Tramitando token...");

      let validation_observer = ctx.validation_observer;
      const form_valid = await validation_observer.validate();

      if (!form_valid) {
        // this.error_messagebox("No se puede enviar el formulario", "Complete los campos correspondientes, por favor.", "Aceptar");
        window.console.warn("NO es posible enviar el formulario: hay campo(s) incompleto(s) y/o incorrecto(s).");
        return;
      }

      ctx.request_in_progress = true; // No se puede apretar el botón de nuevo hasta que regrese de la request

      Axios.post(ctx.form_url, ctx.form_data, { "headers": { "Content-Type": "application/json" } }).then((response) => {
        window.console.warn("--- RESPONSE---");
        window.console.warn(response.data);

        if (response.data.success) {
          ctx.success_messagebox(
                  ctx.$t("newsletter.success_title"),
                  ctx.$t("newsletter.success_message"),
                  ctx.$t("formFeedback.btn_acc"), {
            on_close: () => {
              ctx.resetForm();
              ctx.validation_observer.reset();
            }
          });
        } else {
          ctx.error_messagebox();
        }

        window.console.warn("---/RESPONSE---");
      }).catch((reason) => {
        // ctx.error_messagebox("Error al realizar la petición", reason, "Aceptar");
        ctx.error_messagebox();
      }).finally(() => {
        // window.console.warn("ctx.request_in_progress = false;");
        ctx.request_in_progress = false;
      });

      return false;
    },
  },
};
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.containerFluid
  background-color: var(--color-contrast-lower)
  // margin-top: calc(-1 * var(--borderRadius-d2))
  z-index: 1
  position: relative
  display: flex
  flex-direction: column
  gap: 4rem
.container
  background-color: var(--color-contrast-lower)
  color: #FFF
  box-sizing: border-box
.footerComponent
  display: grid
  grid-template: repeat(4, auto) / 1fr
  flex-direction: column
  justify-content: centerbrands
  align-items: center
  padding: 4rem var(--section-space) 1rem
  place-items: center
  gap: 2rem
  border-bottom: 1px solid var(--color-overlay-20)
  margin-bottom: 2rem
.viewSeparator
  display: none
  margin: 0
.footerLegend
  background-color: #0f1419
  color: white
  position: relative
  z-index: -1
  padding: 0 var(--section-space)
  text-align: center
  span
    font-size: 14px
    padding: 2rem 0
    display: block
    opacity: 0.5
.content
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
.logo
  width: 120px
.kiuLogo
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  // margin-bottom: 2.5rem
  p
    font-size: 1rem
    width: 210px
    line-height: 1.5rem
    text-align: center
    margin-top: 1rem
.brands
  // margin: 2rem 0
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  flex-wrap: wrap
.socialMedia
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  max-width: 200px
  // margin: 1rem auto
  align-content: center
.btnGlyph
  background-color: transparent
  fill: var(--color-contrast-medium)
  opacity: 0.75
  transition: opacity 0.3s ease
  &:hover
    opacity: 1
.contactInfo
  p
    font-size: var(--font-size-sbase)
    // line-height: 15px
    text-align: center
.siteMap
  font-size: var(--font-size-base)
  // line-height: 15px
  grid-template: 1fr / 1fr 1fr
  grid-auto-rows: auto
  display: grid
  align-items: flex-start
  justify-content: flex-start
  flex-wrap: wrap
  gap: 1rem
  padding: 0 var(--section-space)
  border-bottom: 1px solid var(--color-overlay-20)
  margin-bottom: 2rem
  padding-bottom: 1rem
  ul
    list-style: none
    padding: 0
    width: 100%
    margin: 0
    li, a
      margin-bottom: 1rem
      cursor: pointer
      text-decoration: none
      display: flex
      text-transform: capitalize
      line-height: 1.5rem
      &:hover
        &:after
          transform: translateX(0)
          opacity: 0.35
      &:after
        display: none
        content: '➤'
        opacity: 0
        transform: translateX(-10px)
        transition: opacity 0.3s ease, transform 0.3s ease
        width: 10px
        height: 10px
        margin-left: 0.5rem
    li:first-child
      font-weight: 700
      margin: 0rem 0 1.5rem
.newsletter
  width: 100%
  display: flex
  max-width: 450px
  border-radius: 32px
  padding: .5rem
  box-sizing: border-box
  .inputText__input
    border-radius: 24px
  .inputText__feedback
    padding: 0 0 0 1rem    
.newsletter__container
  display: flex
  width: 100%
.newsletter__form
  display: flex
  align-items: flex-start
  gap: .5rem
  width: 100%
  .btn
    height: 48px
    min-width: 48px
    box-sizing: border-box
    border-radius: 24px
    border: 0
  .inputText, .inputText__feedback
    margin: 0
    width: 100%
.sponsors
  width: 100%
  display: flex
  flex-direction: row
  gap: 1rem
  align-items: center
  justify-content: center
  margin-bottom: 2rem
  padding: 0
  box-sizing: border-box
.cta
  width: 100%
  flex: 1
  margin-bottom: 1rem
  button
    justify-content: center
    width: 100%
    margin-top: 2rem
@media (min-width: 768px)
  .btnGlyph
    padding: 0
    background-color: transparent
  .footerComponent
    display: grid
    grid-template: repeat(2, 1fr) / repeat(2, 1fr)
    gap: 1rem 0
  .kiuLogo
    justify-content: flex-start
    flex-direction: row
    margin: 0
    p
      font-size: var(--font-size-xs)
      line-height: 0.8rem
      width: 155px
      margin: 0
      text-align: left
      margin: 0.4rem 1rem 0
  .brands
    width: auto
    margin: 0
  .contactInfo
    p
      text-align: left
  .sponsors
    gap: 3rem
    margin: 0
    // flex-direction: row
    // align-items: flex-start
    // justify-content: space-between
    // width: 150px
  .socialMedia
    margin: 1rem
    justify-self: flex-end
  .footerComponent
    grid-template: 1fr 1fr / 1fr 1fr
    flex-direction: row
    place-items: flex-start
    // justify-content: space-between
    align-items: center
    // padding: 2rem 0
    padding-bottom: 3rem
    .content:first-child
      align-items: flex-start
    .content:last-child
      align-items: flex-end
      // max-width: 300px
    .newsletter
      max-width: 380px
      justify-self: flex-end
    .newsletter__container
      width: 100%
    ::v-deep .inputText__group
      gap: .5rem
      display: flex
      height: auto
      flex-direction: column
  .siteMap
    display: flex
    flex-direction: row
    justify-content: space-between
    border-bottom: 0
    margin-bottom: 0
    padding-bottom: 0
    ul
      width: auto
      flex-basis: auto
      min-width: initial
      margin-top: 1rem
      li, a
        &:after
          display: inline-block
  .cta
    display: flex
    button
      margin-top: 0
      box-sizing: content-box
      padding: 0.25rem 3rem
</style>
