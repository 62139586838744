<template>
    <div class="view">
        <div class="container">

            <ValidationObserver ref="observer-user-reset" v-slot="{ invalid }">
                <form :action="form_url" class="formContainer" @submit.prevent="on_submit" method="post">

                    <div class="inputText">
                        <label class="inputText__label" for="email">{{ $t("form.eMail") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
                        <ValidationProvider class="inputText__group" :rules="{ required: true, email: true }" v-slot="{ errors }">
                            <input class="inputText__input" type="email" id="email" name="email" :placeholder="$t('form.enter')+' '+$t('form.eMail')"
                                    v-model="form_data.email">
                            <em class="inputText__feedback">{{ errors[0] }}</em>
                        </ValidationProvider>
                    </div>

                    <div class="inputText">
                        <label class="inputText__label" for="office_id">{{ $t("form.office_id") }}<em class="muted">* ({{ $t("form.required") }})</em>:</label>
                        <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
                            <input class="inputText__input" type="text" id="office_id" name="office_id" :placeholder="$t('form.enter')+' '+$t('form.office_id')"
                                    v-model="form_data.office_ID">
                            <em class="inputText__feedback">{{ errors[0] }}</em>
                        </ValidationProvider>
                        <p class="inputText__helptext">{{ $t('form.if_you_dont_have_office_id') }}</p>
                    </div>

                    <div class="inputText --textarea">
                        <label class="inputText__label" for="users">{{ $t("form.users") }}<em class="muted">* ({{ $t("form.required") }})</em>: <em class="">({{ $t("form.separated_by") }})</em></label>
                        <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
                            <textarea class="inputText__input" id="users" rows="5" name="users" :placeholder="$t('form.enter')+' '+$t('form.users')"
                                        v-model="form_data.users"></textarea>
                            <em class="inputText__feedback">{{ errors[0] }}</em>
                        </ValidationProvider>
                    </div>
                    <div class="btnContainer">
                        <button class="btn btn-secondary --gradient --cta" type="submit" value="Submit"><span>{{ $t('form.send') }}</span></button>
                    </div>
                </form>
            </ValidationObserver>

            <br>
            <div class="deviceReset__help">
                <p class="lead">{{ $t('form.if_you_have_any_doubts') }}:</p>
                <br>
                <a target="_blank" rel="external" class="btn btn-terciary --gradient" :href="pdf_link">
                    {{ $t('form.device_and_user_reset') }}
                </a>
            </div>            
        </div>

        <formFeedback ref="form-feedback" v-if="form_feedback.active"
                      :title="form_feedback.title" :message="form_feedback.message"
                      :button="{ caption: form_feedback.button_caption }"
                      @close_messagebox="form_feedback.active = false"
        />
    </div>
</template>


<script>
    import Axios from 'axios';
    import {ValidationObserver, ValidationProvider, extend} from 'vee-validate/dist/vee-validate.full';
    import formFeedback from "@/components/MessageBox";

    extend("required", {
        validate: value => !!value,
        // message: "Este campo no debe estar vacío!"
    });

    // extend("email", {
    //     message: "Este campo debe ser un email!"
    // });

    export default {
        components: {
            ValidationObserver,
            ValidationProvider,
            formFeedback
        },
        computed: {
            pdf_link: function () {
                let pdf_path = '/docs/agency';

                return (this.$i18n.locale == 'es'
                    ? `${pdf_path}/reseteo_terminales_usuarios_kiu.pdf`
                    : `${pdf_path}/DEVICE_PASS_RESET_EN.pdf` );
            }
        },
        data() {
            return {
                form_feedback: {
                    $ref: null,
                    // active: true,
                    active: false,
                    title: "",
                    message: "",
                    button_caption: "",
                },
                form_url: `${process.env.VUE_APP_API}/kiu-ws-command/reset-user`,
                validation_observer: null,
                form_data: {
                    language: this.$i18n.locale,
                    email: "",
                    office_ID: "",
                    users: "",
                    token: null,
                    ip: null
                }
            }
        },
        created() {
            this.form_data.token = this.$session.id().split(":")[1];
        },
        mounted() {
            this.validation_observer = this.$refs['observer-user-reset'];

            let oClientIP = document.querySelector("#client_ip");
            let oContext = this;

            if (oClientIP.value) {
                // window.console.log("Got the IP at first attempt: " + oClientIP.value);
                this.form_data.ip = oClientIP.value;
            } else {
                // window.console.log("No IP yet...");
                window._interv_getIP = window.setInterval(function () {
                    let oClientIP = document.querySelector("#client_ip");

                    if (oClientIP.value) {
                        // window.console.log("Got the IP after: " + oClientIP.value);
                        oContext.form_data.ip = oClientIP.value;
                        window.clearInterval(window._interv_getIP);
                    }
                }, 100);
            }
        },
        methods: {
            giveFeedback(title, message, button_caption) { // eslint-disable-line
                this.form_feedback['title'] = title;
                this.form_feedback['message'] = message.replace(/\\n/g, "<br/>");
                this.form_feedback['button_caption'] = button_caption;
                this.form_feedback['active'] = true;
            },
            giveSuccessFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.success.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.success.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            giveErrorFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.error.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.error.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            resetForm() {
                this.form_data.email = "";
                this.form_data.office_ID = "";
                this.form_data.users = "";
            },
            async on_submit() {
                let validation_observer = this.validation_observer;
                const form_valid = await validation_observer.validate();

                if (!form_valid) {
                    window.console.warn("NO se puede enviar el formulario!");
                    return;
                }

                let ctx = this;
                Axios.post(this.form_url, this.form_data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then((response) => {
                    let data = response.data;
                    if (data.success) {
                        ctx.giveSuccessFeedback(ctx.$t('agencies.success'), ctx.$t('agencies.msg_resetSuccess'), ctx.$t('agencies.accept'));
                        ctx.resetForm();
                        ctx.validation_observer.reset();
                    } else {
                        ctx.giveErrorFeedback(data.message, ctx.$t('agencies.msg_userResetError'), ctx.$t('agencies.accept'));
                    }
                });

                return false;
            }
        },
    };
</script>

<style scoped lang="sass">
.view
  padding: 4rem 0
.formContainer
  gap: 1rem
  display: grid
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
@media (min-width: 768px)
  .formContainer
    grid-template: auto auto / 1fr 1fr
    grid-auto-rows: auto
    .btn
      width: auto
  .--textarea
    grid-column: 1/3
</style>
