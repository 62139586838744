<template>
  <div class="navComponent">
    <div class="navMain">
      <div class="container --fluid">
        <div class="container">          
          <router-link to="/" tag="div" class="logo"><img src="../assets/logo.svg" alt="" height="32"></router-link>
          <div class="actionContainer">
            <ul class="mainLinks">
              <router-link to="/about" tag="li" class="sectionLink"><span>{{ $t('nav.about') }}</span></router-link>
              <router-link to="/solutions" tag="li" class="solutionsLink sectionLink"><span>{{ $t('nav.solutions') }}</span>
                <div class="solutionsNav">
                  <ul>
                    <li v-for="solution in solutions"
                        :key="solution.key"
                    >
                      <router-link tag='p' :to="'/solutions/' + solution.key">
                        {{ solution.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </router-link>
              <router-link to="/agencies" class="sectionLink" tag="li"><span>{{ $t('nav.agencies') }}</span></router-link>
              <router-link to="/news" class="sectionLink ok" tag="li">
  <!--               <div class="BlogNav">
                  <ul>
                    <li>
                      <router-link tag="p" to="news">Noticias</router-link>
                    </li>
                    <li>
                      <router-link tag="p" to="news">Insights</router-link>
                    </li>
                    <li>
                      <router-link tag="p" to="news">Eventos</router-link>
                    </li>
                  </ul>
                </div> -->
              <span>{{ $t('nav.news') }}</span>
              </router-link>
              <li class="sectionLink"><span><a href="https://support.kiusys.com/portal/en/signin" target="_blank">{{ $t('nav.helpCenter') }}</a></span></li>
            </ul>
            <router-link to="/contact" class="btn btn-terciary --gradient" tag="button"><span>{{ $t('nav.contact') }}</span></router-link>
            <i18nSwitcher></i18nSwitcher>
            <div class="menuToggler" @click="menuOpen = !menuOpen">
              <font-awesome-icon :icon="['fas', 'bars']"  size="md"/>
            </div>
            <!-- <button class="btn btn-primary">Menu</button> -->
          </div>
        </div>
      </div>
    </div>
    <transition name="fadeNav">
      <Menu v-if="menuOpen"></Menu>
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/menu.vue'
import i18nSwitcher from '@/components/i18nSwitcher'
import solutions from '@/helpers/solutions'

export default {
  name: 'navComponent',
  components: {
    Menu,
    i18nSwitcher
  },
  data() {
    return {
      menuOpen: false,
      BlogNav: false,
      solutions
    }
  },
  methods: {
     Ishover(){
      this.BlogNav = true;
     }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import '../assets/colors.sass'
.navComponent
  position: fixed
  z-index: 1000
  top: 0
  left: 0
  width: 100%
.navMain
  position: relative
  z-index: 100
  .mainLinks
    list-style: none
    display: none
    flex-direction: row
    height: 100%
    .sectionLink, a
      display: flex
      font-size: 1rem
      color: var(--color-white)
      cursor: pointer
      letter-spacing: 1.6px
      align-items: center
      a
        text-decoration: none
  .actionContainer
    height: 5.5rem
    padding-left: 0.5rem
    display: flex
    position: relative
    align-items: center
  svg
    path
      fill: var(--color-white)
  .container.--fluid
    height: 5.5rem
    background: rgba(10, 0, 24, .36)
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
    backdrop-filter: blur(8.1px)
    -webkit-backdrop-filter: blur(8.1px)
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    flex: 1
    flex-direction: row
  .container:not(.--fluid)
    flex-direction: row
    margin: 0 auto
    justify-content: space-between
    padding: 0

.actionContainer
  ul 
    li:hover 
      .solutionsNav
        visibility: visible
        opacity: 1
        bottom: -120px
    .ok:hover
      .BlogNav
        visibility: visible
        opacity: 1
        bottom: -147px
.BlogNav
  .router-link-exact-active:not(.logo):not(.btn)
    background-color: transparent !important
    opacity: 0.7 !important
.BlogNav, .solutionsNav
  position: absolute
  bottom: -130px
  left: -27px
  top: 72px
  visibility: hidden
  opacity: 0
  transition: opacity 0.2s ease, bottom 0.2s ease
  ul
    background: #0b0022
    // border-radius: 0 0 10px 10px
    display: flex
    flex-direction: column
    align-items: center
    list-style: none
    li
      display: flex
      align-items: center
      // border-top: 1px solid #ffffff29
      width: 100%
      text-align: center
      transition: opacity 0.3s ease
      cursor: pointer
      // color: white
      &:hover
        opacity: 1
      &:first-child
        border-top: none
      p
        padding: 0.35rem
        color: var(--color-white)
        font-size: var(--font-size-sbase)
        display: flex
        align-content: center
        align-items: center
        opacity: .6
        line-height: 1.45
.solutionsNav
  left: -40px
  ul
    min-width: 220px
.BlogNav
  ul
    min-width: 140px
.logo
  display: flex
  align-items: center
  position: relative
  left: 1.5rem
.lang-switcher
  display: none
.menuToggler
  width: 3rem
  justify-content: center
  display: flex
  height: 2rem
  align-items: center
.tryADemo
  white-space: nowrap
.router-link-exact-active:not(.logo):not(.btn)
  opacity: 1 !important
  box-shadow: inset 0 -2px 0 0 var(--color-terciary)
// .router-link-exact-active:not(p):not(.logo):not(.btn)
//   background-color: var(--color-overlay-20)
.sectionLink
  transition: box-shadow .2s ease
  &:hover
    box-shadow: inset 0 -2px 0 0 rgba(255,255,255,.25)
.router-link-exact-active.btn
  background-color: white
  color: var(--color-primary) !important
  pointer-events: none
.logo.router-link-active
  cursor: pointer
@media (min-width: 992px)
  .logo
    left: 0
  .menuToggler
    display: none
  .solutionsNav
    width: 600px
    // position: relative
    z-index: 100
    ul
      border: 1px solid var(--color-primary)
      width: 100%
      padding: 0
      // border-radius: var(--b-radius-lg)
      display: grid
      grid-template-rows: 1fr
      grid-template-columns: 1fr 1fr 1fr
      li
        height: 2.75rem
        box-sizing: border-box
        border-radius: 0 !important
        border-bottom: 1px solid var(--color-overlay-10)
        display: flex
        width: 100%
        align-items: center
        background-color: transparent
        justify-content: center
        font-size: var(--font-size-sm)
        transition: background .2s ease
        transition: color .2s ease, background .2s ease
        p
          padding: 0 1rem 0 1.5rem
          height: 100%
          box-sizing: border-box
          text-align: left
          font-size: .8rem
          width: 100%
        &:last-child
          border-color: transparent
        &:hover
          background: linear-gradient(45deg, var(--color-secondary), transparent)
  .navMain
    .container
    .container:not(.--fluid)
      padding: 0 var(--section-space)
    .mainLinks
      display: flex
      margin: 0 1rem 0 0
      padding: 0
      .sectionLink
        position: relative
        transition: opacity 0.2s ease
        span
          position: relative
          z-index: 3
          padding: 1rem
    button
      margin-right: 0
      padding: .75rem 2rem .75rem 1rem
    img
      height: 32px
  .lang-switcher
    display: flex
// @media (min-width: 1500px)
  // .container
    // max-width: 100%
</style>
